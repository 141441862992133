<template>
  <div>
    <v-container>
      <div class="d-flex align-center">
        <v-img
          alt="Золотое сечение"
          contain
          src="@/assets/logo-circle.png"
          max-height="230"
        />
      </div>
      <p class="text-h5 mt-5 text-center font-weight-light">
        Заявка сотрудника Фонда «Золотое сечение»
      </p>
      <v-row class="mt-4" justify="center" align="center">
        <v-card
          tile
          :class="'px-' + horizontalPaddingValue + ' width-86p py-15 pb-15'"
          max-width="1600"
        >
        <p :class="'px-' + horizontalPaddingValue">
          Просим вас заполнить анкету, указав необходимые данные и приложить сканы документов.<br/><br/>   
          По всем возникающим вопросам, пожалуйста, пишите на электронную почту:  
          <a href="mailto:d.biktimirov@zsfond.ru" class="font-weight-light">d.biktimirov@zsfond.ru</a>, 
          <a href="mailto:ovr5@zsfond.ru" class="font-weight-light">ovr5@zsfond.ru</a>. 
        </p>
          <v-form class="mt-4" v-model="isValid">
            <p class="text-h6 font-weight-light mt-3">Сотрудник</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.lastName"
                  :rules="[rules.required]"
                  label="Фамилия"
                  hint="Введите фамилию"
                  color="#af955c"
                  dense
                  :error="!staffInfo.lastName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.firstName"
                  :rules="[rules.required]"
                  label="Имя"
                  hint="Введите имя"
                  color="#af955c"
                  dense
                  :error="!staffInfo.firstName"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.middleName"
                  label="Отчество"
                  hint="Введите отчество"
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="staffInfo.birthdayDate"
                  label="Дата рождения"
                  hint="Укажите дату рождения"
                  color="#af955c"
                  dense
                  type="date"
                  min="1900-01-01"
                  max="2200-12-31"
                  :error="!staffInfo.birthdayDate"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="2" xl="2">
                <v-select
                  v-model="staffInfo.gender"
                  :items="genders"
                  :rules="[rules.required]"
                  label="Пол"
                  hint="Укажите пол"
                  color="#af955c"
                  dense
                  :error="!staffInfo.gender"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="5" md="4" lg="4" xl="3">
                <v-select
                  v-model="staffInfo.campShift"
                  label="Желаемая смена"
                  hint="Выберите смену из списка"
                  color="#af955c"
                  dense        
                  :items="campShifts"
                  item-text="name"
                  item-value="id"
                  :disabled="isRegistrationSuccess"
                  :rules="[rules.required]"
                  :error="!staffInfo.campShift"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="3" xl="3">
                <v-select
                  v-model="staffInfo.position"
                  label="Желаемая должность"
                  hint="Выберите должность из списка"
                  color="#af955c"
                  dense
                  :items="positions"
                  item-text="name"
                  item-value="id"
                  :disabled="isRegistrationSuccess"
                  :rules="[rules.required]"
                  :error="!staffInfo.position"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="staffInfo.clothing_size"
                  label="Размер одежды"
                  hint="Введите размер одежды"
                  dense
                  color="#af955c"
                  :error="!staffInfo.clothing_size"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Контактная информация</p>
            <v-divider></v-divider>
            <p class="text-overline mt-3 ml-6">Контакты</p>
            <v-divider inset></v-divider>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field @copy.prevent @paste.prevent
                  v-model="staffInfo.contacts.email"
                  :rules="[rules.required, rules.email, rules.emailCorrect]"
                  label="E-mail"
                  hint="Введите e-mail адрес"
                  color="#af955c"
                  dense
                  :error="!staffInfo.contacts.email"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="3" xl="3">
                <v-text-field @copy.prevent @paste.prevent
                  v-model="emailRetry"
                  :rules="[
                    rules.required, 
                    rules.email,
                    rules.emailMatch(
                      staffInfo.contacts.email,
                      emailRetry
                    ),
                  ]"
                  label="Подверждение E-mail"
                  hint="Введите e-mail адрес еще раз"
                  color="#af955c"
                  dense
                  :error="!emailRetry"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="4" lg="4" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.phone"
                  :rules="[rules.required, rules.phone]"
                  label="Телефон"
                  hint="Введите номер телефона"
                  v-mask="'+7(###)###-##-##'"
                  color="#af955c"
                  dense
                  type="phone"
                  :error="
                    !staffInfo.contacts.phone ||
                      staffInfo.contacts.phone === '+7('
                  "
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="text-overline mt-3 ml-6">Адрес регистрации</p>
            <v-divider inset></v-divider>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                <v-autocomplete
                  v-model="staffInfo.contacts.address_reg"
                  label="Адрес регистрации"
                  hint="Введите адрес регистрации"
                  :loading="isLoading"
                  :items="regAddressItems"
                  item-text="fullAddress"
                  item-value="id"
                  no-filter
                  :search-input.sync="regAddressSearch"
                  hide-no-data
                  hide-details
                  :rules="[rules.required]"
                  :error="!staffInfo.contacts.address_reg"
                  required
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="4" sm="4" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_reg_house"
                  :rules="[rules.required]"
                  label="Дом"
                  hint="Введите номер дома"
                  color="#af955c"
                  :error="!staffInfo.contacts.address_reg_house"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_reg_building"
                  label="Корпус"
                  hint="Введите корпус/строение (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_reg_flat"
                  label="Квартира"
                  hint="Введите номер квартиры (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="text-overline mt-3 ml-6">Фактический адрес проживания</p>
            <v-divider inset></v-divider>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="12" lg="10" xl="8">
                <v-autocomplete
                  v-model="staffInfo.contacts.address_fact"
                  label="Фактический адрес проживания"
                  hint="Введите фактический адрес проживания"
                  :loading="isLoading"
                  :items="factAddressItems"
                  item-text="fullAddress"
                  item-value="id"
                  no-filter
                  :search-input.sync="factAddressSearch"
                  hide-no-data
                  hide-details
                  :rules="[rules.required]"
                  :error="!staffInfo.contacts.address_fact"
                  required
                  dense
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="4" sm="4" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_fact_house"
                  :rules="[rules.required]"
                  label="Дом"
                  hint="Введите номер дома"
                  color="#af955c"
                  :error="!staffInfo.contacts.address_fact_house"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_fact_building"
                  label="Корпус"
                  hint="Введите корпус/строение (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="3" lg="2" xl="2">
                <v-text-field
                  v-model="staffInfo.contacts.address_fact_flat"
                  label="Квартира"
                  hint="Введите номер квартиры (при наличии)"
                  color="#af955c"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="text-h6 font-weight-light mt-3">Образование</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.education.name"
                  label="Наименование учебного учреждения"
                  hint="Введите название учреждения (без сокращения)"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.education.name"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.education.spec"
                  label="Специальность"
                  hint="Введите специальность"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.education.spec"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Документы</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.documents.passportSeries"
                  label="Серия паспорта"
                  hint="Введите серию паспорта"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.documents.passportSeries"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.documents.passportNumber"
                  label="Номер паспорта"
                  hint="Введите номер паспорта"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.documents.passportNumber"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.documents.medpolis"
                  label="Номер полиса обязательного медицинского страхования"
                  hint="Введите номер полиса"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.documents.medpolis"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="5" xl="4">
                <v-text-field
                  v-model="staffInfo.documents.snils"
                  label="СНИЛС"
                  hint="Введите СНИЛС"
                  color="#af955c"
                  dense
                  :rules="[rules.required]"
                  required
                  :error="!staffInfo.documents.snils"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>

            <p class="text-h6 font-weight-light mt-3">Данные для входа</p>
            <v-divider></v-divider>

            <v-row class="mt-4" justify="center" align="center">
              <v-col cols="12" sm="10" md="6" lg="4" xl="2">
                <v-text-field
                  v-model="staffInfo.login"
                  :rules="[rules.required, rules.minLogin]"
                  label="Логин"
                  hint="Введите логин"
                  color="#af955c"
                  dense
                  required
                  :error="!staffInfo.login"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="3" xl="3">
                <v-text-field
                  v-model="staffInfo.password"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.minPassword]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Пароль"
                  hint="Введите пароль"
                  dense
                  required
                  :error="!staffInfo.password"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="10" md="6" lg="3" xl="3">
                <v-text-field
                  v-model="staffInfo.passwordRepeated"
                  color="#af955c"
                  :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    rules.required,
                    rules.passwordMatch(
                      staffInfo.password,
                      staffInfo.passwordRepeated
                    ),
                  ]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  name="password"
                  label="Подтверждение пароля"
                  hint="Введите пароль еще раз"
                  required
                  :error="!staffInfo.passwordRepeated"
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
      <v-row class="mt-5 mx-4" justify="center" align="center">
        <v-checkbox
          v-model="isUserAgreedPolicy"
          color="#af955c"
          :disabled="isRegistrationSuccess"
        >
          <template v-slot:label>
            <div>
              Продолжая регистрацию, я принимаю условия
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="/docs/staff/agreement.pdf"
                    @click.stop
                    v-on="on"
                    >пользовательского соглашения</a
                  >
                </template>
                Обработка персональных данных производится<br />в соответствии с
                требованиями 152-ФЗ и 242-ФЗ
              </v-tooltip>
              и даю согласие на обработку персональных данных
            </div>
          </template>
        </v-checkbox>
      </v-row>
      <v-row class="mb-5" justify="center" align="center">
        <v-btn
          class="mr-5"
          outlined
          large
          color="#af955c"
          :to="{ name: 'SignIn' }"
        >
          На главную
        </v-btn>
        <v-btn
          outlined
          large
          :disabled="!isValid || !isUserAgreedPolicy || isRegistrationSuccess"
          color="#af955c"
          @click="registration"
          >Зарегистрироваться</v-btn
        >
      </v-row>
      <v-snackbar v-model="resultSnackbar" vertical multi-line timeout="-1">
        <span v-html="resultText"></span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="error"
            text
            v-bind="attrs"
            @click="
              () => {
                resultSnackbar = false;
                resultText = '';
              }">
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <modal-dialog
      :isVisible="isRegistrationSuccess"
      title="Регистрация"
      text="Вы успешно зарегистрировались в личном кабинете. Для продолжения работы, пожалуйста, авторизуйтесь в системе."
      rightButtonText="Авторизоваться"
      :rightButtonAction="routeToMainPage"
    >
    </modal-dialog>
  </div>
</template>

<script>
import axios from "axios";
import _debounce from "debounce";
import CustomModalDialog from "../../components/ModalDialog.vue";

import staffInfo from "../../model/staffInfo";
import inputRules from "../../model/inputRules";
import gendersEnum from "../../model/genders";
import gradesEnum from "../../model/grades";

export default {
  name: "RegistrationView",
  data: () => ({
    isValid: false,
    isLoading: false,
    dateMenu: false,

    isPasswordVisible: false,
    isParentTwoVisible: false,
    isGeneralEducationVisible: true,
    isProfessionalEducationVisible: false,

    isRegistrationSuccess: false,

    genders: gendersEnum,

    campShifts: [],
    positions: [],

    regAddressSearch: null,
    regAddressItems: [],

    factAddressSearch: null,
    factAddressItems: [],

    resultSnackbar: false,
    resultText: "",

    isUserAgreedPolicy: false,

    staffInfo: JSON.parse(JSON.stringify(staffInfo)),
    generalEducationTypes: [],
    professionalEducationTypes: [],
    grades: gradesEnum,

    emailRetry: "",
    foreignLanguages: [],

    rules: { ...inputRules },
    token: null,
  }),
  components: {
    "modal-dialog": CustomModalDialog,
  },
  computed: {
    staffInfoStringify() {

      this.staffInfo.documents.passport = [
        this.staffInfo.documents.passportSeries,
        this.staffInfo.documents.passportNumber,
      ].join(" ");

      return JSON.stringify(this.staffInfo);
    },
    horizontalPaddingValue() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 5;
        case "md":
          return 8;
        case "lg":
          return 10;
        case "xl":
          return 10;
      }
    },
    debouncedRegAddressSearch() {
      return _debounce(this.searchRegAddress, 700);
    },
    debouncedFactAddressSearch() {
      return _debounce(this.searchFactAddress, 700);
    },
  },
  methods: {
    getToken() {
      let tokenElements = document.getElementsByName(
        "__RequestVerificationToken"
      );
      if (tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
    async registration() {
      this.$emit("set-processing", true);
      try {
        let regData = new FormData();

        regData.append("staffInfo", this.staffInfoStringify);
        let response = await axios.post("/api/postStaffRegistration", regData, {
          headers: {
            "Content-Type": "multipart/form-data",
            RequestVerificationToken: this.token,
          },
        });
        this.getRegistrationResponse(response.data);
      } catch (error) {
        let data = {
          success: false,

          message: error.message /*error.message*/, //"Ошибка регистрации. Пожалуйста, повторите попытку или обратитесь в службу поддержки.",
        };
        this.getRegistrationResponse(data);
      }
    },
    getRegistrationResponse(data) {
      this.resultText = "";
      this.resultSnackbar = false;

      this.$emit("set-processing", false);

      if (data.success === true) {
        this.isRegistrationSuccess = true;
      } else {
        this.isRegistrationSuccess = false;
        this.resultText = data.message;
        this.resultSnackbar = true;
      }
    },
    async getCampShift() {
      try {
        let response = await axios.get("/api/getstaffcampshift", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.campShifts = response.data;
      } catch (error) {
        this.campShifts = [];
      }
    },
    async getCampPositions() {
      try {
        let response = await axios.get("/api/getstaffpositions", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.positions = response.data;
      } catch (error) {
        this.positions = [];
      }
    },
    async searchAddress(searchInput) {
      this.isLoading = true;
      let items = [];
      try {
        let response = await axios.get(
          "/api/getAddresses?query=" + encodeURIComponent(searchInput),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        items = response.data;
      } catch (error) {
        items = [];
      } finally {
        this.isLoading = false;
      }
      return items;
    },

    async searchRegAddress() {
      const items = await this.searchAddress(this.regAddressSearch) || [];
      this.regAddressItems = items;
    },
    async searchFactAddress() {
      const items = await this.searchAddress(this.factAddressSearch) || [];
      this.factAddressItems = items;
    },
    setFirstNumAtPhones() {
      this.staffInfo.contacts.phone = "+7(";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    routeToMainPage() {
      this.$router.push("/").catch(() => {});
    },
  },
  watch: {
    staffInfoStringify: {
      handler(val, oldVal) {
        const newstaffInfo = JSON.parse(val);
        const oldstaffInfo = JSON.parse(oldVal);

        if (newstaffInfo.birthdayDate !== oldstaffInfo.birthdayDate) {
          this.staffInfo.birthdayDateFormated = this.formatDate(
            newstaffInfo.birthdayDate
          );
        }
      },
    },
    regAddressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedRegAddressSearch();
      }
    },

    factAddressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedFactAddressSearch();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "HowTo") {
      this.$emit("increment-cache-key");
    }
    next();
  },
  created() {
    this.getToken();
    this.getCampPositions();
    this.getCampShift();
  },
  mounted() {
    this.setFirstNumAtPhones();
  },
};
</script>
<style scoped>
  .width-86p{
    width: 86%;
  }

</style>
